<template>
  <div class="container-fluid mb-4">
    <div class="row mt-4 mb-4">
    <el-tabs v-model="activeTab" class="tabs mt-4" @tab-click="handleTabChange">
      <el-tab-pane
        v-for="tab in tabs"
        :key="tab.name"
        :label="tab.label"
        :name="tab.name"
      />
    </el-tabs>
  </div>
    <div class="row">
      <div class="col-12 btn-right mt-4 mb-4">
        <app-pagination
          :total="total"
          :current-page="page"
          :page-size="pageSize"
          @page-change="handlePageChange"
        />
      </div>
    </div>
    <div class="mt-4">
      <users-table :items="items" :is-loading="isLoading" />
    </div>
  </div>
</template>

<script>
import Database from '@/utils/services/Database';
import UsersTable from '@/components/tables/Users';

export default {
  name: 'AdminUsers',
  components: {
    UsersTable,
  },
  data() {
    return {
      items: [],
      isLoading: false,
      pageSize: 20,
      page: 1,
      total: 1,
      tabs: [
        {
          label: 'Verified',
          name: 'verified',
        },
        {
          label: 'Awaiting verification',
          name: 'awaiting_verification',
        }
      ],
      activeTab: 'verified'
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const res = await Database.getAll('users', {
        limit: this.pageSize,
        offset: this.page * this.pageSize - this.pageSize,
        status: this.activeTab
      });
      this.total = res.total;
      this.items = res.data;
      this.isLoading = false;
    },
    async handlePageChange(val) {
      this.page = val;
      await this.getData();
    },
    async handleTabChange(tab) {
      this.activeTab = tab.paneName;
      await this.getData();
    },
  },
};
</script>
