<template>
  <el-table
    :data="items"
    :border="true"
    highlight-current-row
    v-loading="isLoading"
  >
    <el-table-column prop="first_name" label="First name" />
    <el-table-column prop="last_name" label="Last name" />
    <el-table-column prop="username" label="Username" />
    <el-table-column prop="email" label="Email" />
    <el-table-column prop="company" label="Company" />
  </el-table>
</template>

<script>
export default {
  name: 'UsersTable',
  props: ['items', 'isLoading'],
};
</script>
